/* Authentication.css */
.authentication-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(90deg, rgb(59, 38, 102), rgb(31, 43, 86));
    padding: 20px;
    padding-top: 150px;
}

.authentication-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: center;
}

.authentication-form {
    width: 100%;
    max-width: 400px;
    background-color: #ffffff2e;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.authentication-form input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    background: rgba(255, 255, 255, 0.774);
}

.authentication-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
}

.authentication-form button:disabled {
    background-color: #ccc;
}

.error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 10px;
}

.toggle-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.toggle-form p{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.toggle-form button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 1rem;
}

.toggle-form button:hover {
    text-decoration: underline;
}
