.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-top: 150px;
  }
  
  .contact-container h1, h2 {
    text-align: center;
    color: #333;
  }
  
  .contact-info {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .contact-info p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .feedback-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .contact-container label {
    font-size: 16px;
    color: #333;
  }
  
  .contact-container input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
  }
  
  .contact-container textarea {
    resize: vertical;
    height: 100px;
  }
  
  .submit-button {
    padding: 10px 15px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #218838;
  }
  
  @media (max-width: 600px) {
    .contact-container {
      padding: 15px;
    }
  
    .contact-container  h1, h2 {
      font-size: 24px;
    }
  
    .contact-info p {
      font-size: 16px;
    }
  }
  