.dashboard-container {
    padding: 20px;
    margin: auto;
    background: linear-gradient(90deg, rgb(59, 38, 102), rgb(31, 43, 86));
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding-top: 150px;
    padding-bottom: 150px;
    height: 120vh;
  }
  
  .dashboard-container h1 {
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
    font-family: 'Arial', sans-serif;
  }
  
  .requests-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .request-card {
    border: none;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    color: #333;
  }

  .no-bookings{
    text-align: center;
    color: white;
  }
  
  .request-card h3 {
    margin-top: 0;
    color: #333;
  }
  
  .request-card p {
    margin: 5px 0;
  }
  
  .buttons {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }
  
  .accept-button, .deny-button, .call-button {
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .accept-button {
    background-color: #4CAF50; /* Green */
    color: white;
  }
  
  .accept-button:hover {
    background-color: #45a049;
    transform: scale(1.05);
  }
  
  .deny-button {
    background-color: #f44336; /* Red */
    color: white;
  }
  
  .deny-button:hover {
    background-color: #e53935;
    transform: scale(1.05);
  }
  
  .call-button {
    background-color: #2196F3; /* Blue */
    color: white;
  }
  
  .call-button:hover {
    background-color: #1e88e5;
    transform: scale(1.05);
  }

  .call-button a{
    color: white;
    text-decoration: none;
  }
  