.profile-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 150px;
}
.profile-card {
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 500px;
    text-align: left;
  }
  
  .profile-header {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .profile-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
  
  .profile-item {
    font-size: 1.1rem;
    color: #555;
    margin: 10px 0;
  }
  
  .profile-item strong {
    color: #000;
  }
  
  .profile-item span {
    margin-left: 10px;
    font-weight: 500;
  }
  
  .profile-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(45deg, #6b73ff, #000dff);
    border-radius: 15px;
    opacity: 0.2;
    z-index: -1;
  }
  
  @media (max-width: 600px) {
    .profile-card {
      padding: 15px;
      max-width: 90%;
    }
  
    .profile-header {
      font-size: 1.5rem;
    }
  
    .profile-item {
      font-size: 1rem;
    }
  }
  


  /* Profile card styling remains the same */

.edit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 20px;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    width: 400px;
    max-width: 100%;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 10px;
  }
  
  .save-button:hover {
    background-color: #218838;
  }
  
  .cancel-button:hover {
    background-color: #c82333;
  }
  